export const convertToKebabCase = (category) => {
  return category ? category.replace(/\s+/g, "-").toLowerCase() : "";
};

export const convertToPascalCase = (category) => {
  
  return category ? category
    .replace(/-/g, " ")
    .replace(/(\w)(\w*)/g, function (_, firstLetter, restOfWord) {
      return firstLetter.toUpperCase() + restOfWord.toLowerCase();
    }) : "";
};

export const metalObj = [
  {
    id: 1,
    name: "Sterling Silver",
  },
  {
    id: 2,
    name: "Rose Gold",
  },
  {
    id: 3,
    name: "White Gold",
  },
  {
    id: 4,
    name: "Yellow Gold",
  },
  {
    id: 5,
    name: "Platinum",
  },
];
