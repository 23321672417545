import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addToCart,
  listAllCart,
  listCart,
  removeFromCart,
} from "../../redux/services/cartServices";
import { useSelector } from "react-redux";

const initialState = {
  cartList: {},
  cartStatus: false,
};

export const selectStatus = (state) => state.cart.cartStatus;

export const productAddToCart = createAsyncThunk(
  "cart/productAddToCart",
  async (payload) => {
    return await addToCart(payload);
  }
);

export const productRemoveFromCart = createAsyncThunk(
  "cart/productRemoveFromCart",
  async (payload) => {
    return await removeFromCart(payload);
  }
);

export const listCartProduct = createAsyncThunk(
  "cart/listCartProduct",
  async () => {
    return await listCart();
  }
);

export const listCartAll = createAsyncThunk(
  "cart/listCartAll",
  async (payload) => {
    return await listAllCart(payload);
  }
);

export const selectTotalCartProducts = (state) => state.cart.cartList;

const cartSlice = createSlice({
  name: "cartSlice",
  initialState,
  reducers: {
    listLocalCartProduct: (state, action) => {
      const list = JSON.parse(localStorage.getItem("cart"));
      state.cartList = list;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listCartProduct.fulfilled, (state, action) => {
      state.cartList = action?.payload?.data?.Data;
    });
    builder.addCase(productAddToCart.fulfilled, (state, action) => {
      state.cartStatus =
        action?.payload?.data?.Message === "Product added in cart"
          ? true
          : false;
    });
  },
});

export const { listLocalCartProduct } = cartSlice.actions;

export default cartSlice.reducer;

export const useTotalProductsInCart = () => {
  const list = useSelector(selectTotalCartProducts);
  let total = 0;
  if (list && list.length > 0) {
    for (let i = 0; i < list.length; i++) {
      total = total + list[i].quantity;
    }
  }
  return total;
};

export const useCartStatus = () => {
  const cartStatus = useSelector(selectStatus);

  return cartStatus;
};
