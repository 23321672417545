import {
  BANNER,
  BLOG,
  GET_ONE_BLOG,
  LIST_BLOG,
  REMOVE_BLOG,
} from "../../api/constApi";
import authHeader, { imageHeader } from "./authHeader";
import { apiInstance } from "./axiosApi";

export const addBlog = (payload) => {
  return apiInstance.post(BLOG, payload, { headers: authHeader() });
};

export const removeBLog = (payload) => {
  return apiInstance.post(REMOVE_BLOG, payload, { headers: authHeader() });
};

export const listBlog = () => {
  return apiInstance.get(LIST_BLOG, { headers: authHeader() });
};

export const getOneBlog = (payload) => {
  return apiInstance.get(`${GET_ONE_BLOG}${payload.blogid}`, payload);
};

export const banner = (payload) => {
  return apiInstance.post(BANNER, payload, { headers: imageHeader() });
};
