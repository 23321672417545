import React, { useEffect, useState, lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useAuthenticate, useRole } from "../component/auth/authSlice";
import mainLogo from "../asset/images/logo/main-logo.svg";
import ReviewList from "../pages/Admin/ReviewList";
const Home = lazy(() => import("../pages/landingPage/Home"));
const Register = lazy(() => import("../component/auth/Register"));
const AboutUs = lazy(() => import("../pages/aboutUs/AboutUs"));
const Blog = lazy(() => import("../pages/blog/Blog"));
const Faq = lazy(() => import("../pages/faq/Faq"));
const ContactUs = lazy(() => import("../pages/contactUs/ContactUs"));
const PrivacyPolicy = lazy(() =>
  import("../pages/privacyPolicy/PrivacyPolicy")
);
const TermsAndConditions = lazy(() =>
  import("../pages/termsAndConditions/TermsAndConditions")
);
const CustomerReviews = lazy(() =>
  import("../pages/customerReviews/CustomerReviews")
);
const HtmlSitemap = lazy(() => import("../pages/htmlSitemap/HtmlSitemap"));
const Account = lazy(() => import("../pages/account/Account"));
const AddProduct = lazy(() => import("../pages/Admin/AddProduct"));
const BlogDetails = lazy(() => import("../pages/blog/BlogDetails"));
const ContactForm = lazy(() => import("../pages/contactUs/ContactForm"));
const Products = lazy(() => import("../pages/product/Products"));
const ProductDetails = lazy(() => import("../pages/product/ProductDetails"));
const AdminDashboard = lazy(() => import("../pages/Admin/AdminDashboard"));
const UserList = lazy(() => import("../pages/Admin/UserList"));
const AddCategory = lazy(() => import("../pages/Admin/AddCategory"));
const Checkout = lazy(() => import("../pages/checkout/Checkout"));
const Wishlist = lazy(() => import("../pages/wishlist/Wishlist"));
const OrderDetails = lazy(() => import("../pages/Admin/OrderDetails"));
const PaymentSuccess = lazy(() => import("../pages/payment/PaymentSuccess"));
const AddBlog = lazy(() => import("../pages/Admin/AddBlog"));
const EnquiryList = lazy(() => import("../pages/Admin/EnquiryList"));
const ListCart = lazy(() => import("../pages/checkout/ListCart"));
const AddCoupon = lazy(() => import("../pages/Admin/AddCoupon"));

const ShippingPolicy = lazy(() =>
  import("../pages/shippingPolicy/ShippingPolicy")
);
const ShippingInformation = lazy(() =>
  import("../pages/shippingInformation/ShippingInformation")
);
const ReturnExchangePolicy = lazy(() =>
  import("../pages/returnExchangePolicy/ReturnExchangePolicy")
);
const Success = lazy(() => import("../component/auth/Success"));
const PageNotFound = lazy(() => import("../pages/pageNotFound/PageNotFound"));

function AllRoutes() {
  const role = useRole();
  const authenticate = useAuthenticate();
  const [selectedOption, setSelectedOption] = useState("user");

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    localStorage.setItem("selectedOption", option);
  };

  useEffect(() => {
    // Retrieve the selected option from local storage when the component mounts.
    const storedOption = localStorage.getItem("selectedOption");
    if (storedOption) {
      setSelectedOption(storedOption);
    }
  }, []);
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div className="fixed inset-0 w-screen h-screen flex items-center justify-center backdrop-blur-sm z-50">
            <img className="w-auto h-28 sharp py-2 animate-bounce" src={mainLogo} alt="DiamZoo Logo" loading="lazy" width={'200px'} height={'112px'} />
          </div>
        }
      >
        <Routes className="main min-h-screen h-ful w-full">
          <Route path="/">
            <Route index element={<Home />} />
            <Route
              path="account"
              element={authenticate ? <Account /> : <Home />}
            />
            <Route path="auth">
              <Route path="success" element={<Success />} />
              <Route path="register" element={<Register />} />
            </Route>
            <Route path="gt_adam">
              <Route
                index
                element={
                  role ? (
                    <>
                      <div className="main flex min-h-screen">
                        <AdminDashboard onSelectOption={handleSelectOption} />
                        {selectedOption === "product" ? <AddProduct /> : <></>}
                        {selectedOption === "category" ? (
                          <AddCategory />
                        ) : (
                          <></>
                        )}
                        {selectedOption === "user" ? <UserList /> : <></>}
                        {selectedOption === "order" ? <OrderDetails /> : <></>}
                        {selectedOption === "blog" ? <AddBlog /> : <></>}
                        {selectedOption === "enquiry" ? <EnquiryList /> : <></>}
                        {selectedOption === "review" ? <ReviewList /> : <></>}
                        {selectedOption === "catelogue" ? <AddCoupon /> : <></>}
                      </div>
                    </>
                  ) : (
                    <Home />
                  )
                }
              />
            </Route>
            <Route path="products" element={<Products />} />
            <Route path="products/:category">
              <Route path="" element={<Products />} />
              <Route path=":subcategory" element={<Products />} />
              <Route path=":style/:subcategory" element={<Products />} />
            </Route>
            <Route path="product/:category/:productslug" element={<ProductDetails />} />
            {/* <Route path="product-details" element={<ProductDetails />} /> */}
            <Route
              path="checkout"
              element={authenticate ? <Checkout /> : <Home />}
            />
            <Route
              path="wishlist"
              element={authenticate ? <Wishlist /> : <Home />}
            />
            <Route path="cart" element={<ListCart />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="shipping-policy" element={<ShippingPolicy />} />
            <Route
              path="shipping-information"
              element={<ShippingInformation />}
            />
            <Route
              path="return-exchange-policy"
              element={<ReturnExchangePolicy />}
            />
            <Route path="blog" element={<Blog />} />
            <Route path="blog-details" element={<BlogDetails />} />
            <Route path="faq" element={<Faq />} />
            <Route path="contact-section" element={<ContactUs />} />
            <Route path="contact-us" element={<ContactForm />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route
              path="terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="customer-reviews" element={<CustomerReviews />} />
            <Route path="html-sitemap" element={<HtmlSitemap />} />
            <Route path="success" element={<PaymentSuccess />} />
            <Route
              path="cancel"
              element={
                <h1 style={{ color: "red", margin: "50px" }}>Payment Cancel</h1>
              }
            />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default AllRoutes;
