/* AUTH */
export const SIGNUP = "/api/signup";
export const SIGNIN = "/api/signin";
export const PROFILE = "/api/profile";
export const FORGOT_PASSWORD = "/api/forgotpassword";
export const CHANGE_PASSWORD = "/api/changepassword";
export const VERIFY_OTP = "/api/verifyotp";
export const UNVERIFY_USER = "/api/unverifyuser";
export const PAY = "/api/pay";

/* PROFILE */
export const USER = "/api/user";
export const REMOVE_USER = "/api/removeuser";
export const GET_ALL_USER = "/api/getalluser";
export const ADDRESS = "/api/address";
export const REMOVE_ADDRESS = "/api/removeaddress";

/* WISHLIST */
export const ADD_TO_WISHLIST = "/api/addtowishlist";
export const LIST_WISHLIST = "/api/listwishlist";
export const LIST_ALL_WISHLIST = "/api/listallwishlist";
export const CHECK_WISHLIST = "/api/checkwishlist";

/* CART */
export const ADD_TO_CART = "/api/addtocart";
export const REMOVE_FROM_CART = "/api/removefromcart";
export const LIST_CART = "/api/listcart";
export const LIST_ALL_CART = "/api/listallcart";

/* CATEGORY */
export const CATEGORY = "/api/category";
export const REMOVE_CATEGORY = "/api/removecategory";
export const LIST_CATEGORY = "/api/category";
export const GET_ONE_CATEGORY = "/api/getonecategory?category=";
export const CATEGORY_PIC = "/api/categorypic";

/* METAL CATEGORY */
export const METAL_CATEGORY = "/api/metalcategory";
export const REMOVE_METAL_CATEGORY = "/api/removemetalcategory";
export const REMOVE_METAL_MATERIAL = "/api/removemetalmaterial";
export const REMOVE_METAL_DIAMOND_TYPE = "/api/removemetaldiamondtype";
export const METAL_MATERIAL = "/api/metalmaterial";
export const METAL_DIAMOND_TYPE = "/api/metaldiamondtype";
export const LIST_METAL_CATEGORY = "/api/metalcategory";
export const GET_ONE_METAL_CATEGORY = "/api/getonemetalcategory?metalcategory=";

/* METAL */
export const METAL = "/api/metal";
export const LIST_METAL = "/api/listmetal";
export const GET_ONE_METAL = "/api/getonemetal";
export const REMOVE_METAL = "/api/removemetal";

/* PRODUCT */
export const THUMBNAIL = "/api/thumbnail";
export const EXCEL = "/api/excel";
export const IMAGE = "/api/image";
export const VIDEO = "/api/video";
export const PRODUCT = "/api/product";
export const LIST_PRODUCT = "/api/listproduct";
export const LIST_USER_SIDE_PRODUCT = "/api/listusersideproduct";
export const GET_ONE_PRODUCT = "/api/getoneproduct";
export const REMOVE_PRODUCT = "/api/removeproduct";
export const REMOVE_MEDIA = "/api/removemedia";
export const CHANGE_PRODUCT_STATUS = "/api/changeproductstatus";

/* ENQUIRY */
export const ENQUIRY = "/api/enquiry";
export const DESIGN_PIC = "/api/designpic";
export const ENQUIRY_TAKEN = "/api/enquirytaken";
export const REMOVE_ENQUIRY = "/api/removeenquiry";

/* BLOG */
export const BLOG = "/api/blog";
export const REMOVE_BLOG = "/api/removeblog";
export const LIST_BLOG = "/api/listblog";
export const GET_ONE_BLOG = "/api/getoneblog?blogid=";
export const BANNER = "/api/banner";

/* GLOBAL SEARCH */
export const SEARCH = "/api/search";
export const SEARCH_ADMIN_PRODUCT = "/api/searchadminproduct";
export const SEARCH_ADMIN_METAL = "/api/searchadminmetal";

/* ORDER */
export const LIST_ORDER = "/api/listorder";
export const LIST_USER_ORDER = "/api/listuserorder";
export const CHECK_USER = "/api/checkuser";

/* COUPON */
export const ADD_COUPON = "/api/addcoupon";
export const REMOVE_COUPON = "/api/removecoupon";
export const GET_COUPON = "/api/getcoupon";
export const LIST_COUPON = "/api/listcoupon";
export const VALIDATE_COUPON = "/api/validatecoupon";

/* REVIEW */
export const REVIEW = "/api/review";
export const LIST_REVIEW = "/api/listreview";
export const REMOVE_REVIEW = "/api/removereview";
export const REVIEW_PHOTO = "/api/reviewphoto";
export const UNVERIFY_REVIEW = "/api/unverifyreview";