import { ADD_TO_CART, LIST_ALL_CART, LIST_CART, REMOVE_FROM_CART } from "../../api/constApi";
import authHeader from "./authHeader";
import { apiInstance } from "./axiosApi";

export const addToCart = (payload) => {
  return apiInstance.post(ADD_TO_CART, payload, { headers: authHeader() });
};

export const removeFromCart = (payload) => {
  return apiInstance.post(REMOVE_FROM_CART, payload, { headers: authHeader() });
};

export const listCart = () => {
  return apiInstance.get(LIST_CART, { headers: authHeader() });
};

export const listAllCart = (payload) => {
  return apiInstance.get(`${LIST_ALL_CART}?page=${payload.page}&limit=${payload.limit}`, { headers: authHeader() });
};
