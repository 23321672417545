import {
  CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  SIGNIN,
  SIGNUP,
  UNVERIFY_USER,
  VERIFY_OTP,
} from "../../api/constApi";
import authHeader from "./authHeader";
import { apiInstance } from "./axiosApi";

export const signUp = (payload) => {
  return apiInstance.post(SIGNUP, payload);
};

export const signIn = (payload) => {
  return apiInstance.post(SIGNIN, payload);
};

export const forgotPassword = (payload) => {
  return apiInstance.post(FORGOT_PASSWORD, payload);
};

export const changePassword = (payload) => {
  return apiInstance.post(CHANGE_PASSWORD, payload);
};

export const verifyOtp = (payload) => {
  return apiInstance.post(VERIFY_OTP, payload);
};

export const unverifyUser = (payload) => {
  return apiInstance.post(UNVERIFY_USER, payload, {headers: authHeader()});
};