import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addCategory,
  addMetalCategory,
  addMetalDiamondType,
  addMetalMaterial,
  addSubCategory,
  categoryPic,
  getOneCategory,
  getOneMetalCategory,
  listCategory,
  listMetalCategory,
  removeCategory,
  removeMetalCategory,
  removeMetalDiamondType,
  removeMetalMaterial,
  removeSubCategory,
} from "../../redux/services/categoryServices";

const initialState = {
  categoryList: {},
  metalCategoryList: {},
};

export const categoryAdd = createAsyncThunk(
  "category/categoryAdd",
  async (payload) => {
    return await addCategory(payload);
  }
);

export const categoryPicAdd = createAsyncThunk(
  "category/categoryPicAdd",
  async (payload) => {
    return await categoryPic(payload);
  }
);

export const metalCategoryAdd = createAsyncThunk(
  "category/metalCategoryAdd",
  async (payload) => {
    return await addMetalCategory(payload);
  }
);

export const metalMaterialAdd = createAsyncThunk(
  "category/metalMaterialAdd",
  async (payload) => {
    return await addMetalMaterial(payload);
  }
);

export const metalDiamondTypeAdd = createAsyncThunk(
  "category/metalDiamondTypeAdd",
  async (payload) => {
    return await addMetalDiamondType(payload);
  }
);

export const subCategoryAdd = createAsyncThunk(
  "category/subCategoryAdd",
  async (payload) => {
    return await addSubCategory(payload);
  }
);

export const getCategoryList = createAsyncThunk(
  "category/getCategoryList",
  async () => {
    return await listCategory();
  }
);


export const getCategoryData = createAsyncThunk(
  "category/getOneCategory",
  async (category) => {
    return await getOneCategory(category);
  }
);

export const getMetalCategoryList = createAsyncThunk(
  "category/getMetalCategoryList",
  async () => {
    return await listMetalCategory();
  }
);

export const getMetalCategoryData = createAsyncThunk(
  "category/getOneMetalCategory",
  async (metalCategory) => {
    return await getOneMetalCategory(metalCategory);
  }
);


export const categoryRemove = createAsyncThunk(
  "category/categoryRemove",
  async (payload) => {
    return await removeCategory(payload);
  }
);

export const subCategoryRemove = createAsyncThunk(
  "category/subCategoryRemove",
  async (payload) => {
    return await removeSubCategory(payload);
  }
);

export const metalCategoryRemove = createAsyncThunk(
  "category/metalCategoryRemove",
  async (payload) => {
    return await removeMetalCategory(payload);
  }
);


export const metalMaterialRemove = createAsyncThunk(
  "category/metalMaterialRemove",
  async (payload) => {
    return await removeMetalMaterial(payload);
  }
);

export const metalDiamondTypeRemove = createAsyncThunk(
  "category/metalDiamondTypeRemove",
  async (payload) => {
    return await removeMetalDiamondType(payload);
  }
);
const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCategoryList.fulfilled, (state, action) => {
      state.categoryList = action?.payload?.data?.Data;
    });
    builder.addCase(getMetalCategoryList.fulfilled, (state, action) => {
      state.metalCategoryList = action?.payload?.data?.Data;
    });
  },
});

export default categorySlice.reducer;
