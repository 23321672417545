import { ADD_TO_WISHLIST, CHECK_WISHLIST, LIST_ALL_WISHLIST, LIST_WISHLIST } from "../../api/constApi";
import authHeader from "./authHeader";
import { apiInstance } from "./axiosApi";

export const addToWishlist = (payload) => {
  return apiInstance.post(ADD_TO_WISHLIST, payload, { headers: authHeader() });
};

export const listWishlist = () => {
  return apiInstance.get(LIST_WISHLIST, { headers: authHeader() });
};

export const listAllWishlist = (payload) => {
  return apiInstance.get(`${LIST_ALL_WISHLIST}?page=${payload.page}&limit=${payload.limit}`, { headers: authHeader() });
};

export const checkWishlist = (payload) => {
  return apiInstance.post(CHECK_WISHLIST, payload, { headers: authHeader() });
};
