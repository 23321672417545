import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { enquiryDelete, enquiryDone, getEnquiry } from "../product/enquirySlice";
import { s3Url } from "../../api/baseUrl";
import { reviewList, reviewRemove, reviewUnverify } from "./productSlice";
import { Link } from "react-router-dom";
import { convertToKebabCase } from "../../component/core/helper";

export default function ReviewList() {
  const dispatch = useDispatch();

  const [reviewsList, setReviewsList] = useState([]);
  const [total, setTotal] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const getAllReviewList = async (page) => {
    const payload = {
      page: page ? page : 1,
      limit: 10,
    };
    try {
      const response = await dispatch(reviewList(payload)).unwrap();
      if (response.data.IsSuccess) {
        setTotal(response.data.Data.total);
        setReviewsList(response.data.Data.list);
      } else {
        // console.log(response.data.Message);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const deleteReview = async (id) => {
    const payload = {
      productid: id.productid,
      reviewid: id.reviewid,
    };
    try {
      const response = await dispatch(reviewRemove(payload)).unwrap();
      if (response.data.IsSuccess) {
        getAllReviewList();
        // setEnquiryList(response.data.Data);
      } else {
        // console.log(response.data.Message);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    const temp = localStorage.getItem("user") ? getAllReviewList() : {};
  }, []);

  const onPageChange = (event) => {
    setCurrentPage(event.page + 1);
    getAllReviewList(event.page + 1);
  };

  const handleReviewStatus = async (id) => {
    const payload = {
      reviewid: id,
    };
    try {
      const response = await dispatch(reviewUnverify(payload)).unwrap();
      if (response.data.IsSuccess) {
        getAllReviewList();
      } else {
        // console.log(response.data.Message);
      }
    } catch (error) {
      // console.log(error);
    }
  };
  const columns = [
    {
      header: "Product id",
      field: (row) => {
        return <Link aria-label="Redirect To Product Details" className="text-sm font-bold" target="_blank" to={`/product/${convertToKebabCase(row.productid.category)}/${convertToKebabCase(row.productid.header_name)}?productid=${row.productid._id}&metal=Sterling Silver`}>{row?.productid?.p_id}</Link>;
      },
    },
    {
      header: "User",
      field: (row) => {
        return <span className="text-sm">{row?.name}</span>;
      },
    },
    {
      header: "Title",
      field: (row) => {
        return <span className="text-sm">{row.title}</span>;
      },
    },
    {
      header: "Review",
      field: (row) => {
        return <span className="text-sm">{row.review}</span>;
      },
    },
    {
      header: "Rating",
      field: (row) => {
        return (
          <span className="text-sm block whitespace-normal max-w-xs">
            {row?.rating}
          </span>
        );
      },
    },
    {
      header: "Verified",
      field: (row) => {
        return (
          <span className="text-sm">
            {row.verified === true ? (
              <span>&#9989;</span>
            ) : (
              <span>&#10060;</span>
            )}
          </span>
        );
      },
    },
    {
      header: "Action",
      field: (row) => {
        return (
          <>
            <button
              className="btn btn-dark"
              onClick={() => handleReviewStatus(row._id)}
              aria-label="Verified"
            >
              {row.verified === true ? "Unverify" : "Verify"}
            </button>
            <button
              className="btn btn-dark ml-4"
              aria-label="Delete"
              onClick={() => deleteReview({ productid: row.productid._id, reviewid: row._id })}
            >
              Delete
            </button>

          </>);
      },
    },
  ];
  return (
    <>
      <div className="w-full lg:max-w-[calc(100%-230px)]">
        {/* <!-- Content In --> */}
        <div className="rightInContent relative">
          <div className="px-7 xl:px-12 min-h-full relative">
            <div className="flex items-center justify-between mb-5 sm:mb-10">
              <h3 className="text-2xl font-play font-bold tracking-wider leading-8">
                Review List
              </h3>
            </div>
            <DataTable
              value={reviewsList}
              columnResizeMode={"fit"}
              resizableColumns={true}
              scrollable={false}
              className="w-full"
            >
              {columns.map((col) => (
                <Column key={col.field} field={col.field} header={col.header} className={col.header === "Review" ? '!whitespace-pre-wrap' : ''} />
              ))}
            </DataTable>
            <Paginator
              first={
                reviewsList.length === 0
                  ? 0
                  : currentPage * itemsPerPage - itemsPerPage
              }
              rows={itemsPerPage}
              totalRecords={total}
              onPageChange={onPageChange}
            />
          </div>
        </div>
      </div>
    </>
  );
}
