import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { pay } from "../../redux/services/orderServices";

const initialState = {
  orderList: [],
};

export const paymentPay = createAsyncThunk(
  "order/paymentPay",
  async (payload) => {
    return await pay(payload);
  }
);

const orderSlice = createSlice({
  name: "orderSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export default orderSlice.reducer;
