import {
  CHANGE_PRODUCT_STATUS,
  EXCEL,
  GET_ONE_METAL,
  GET_ONE_PRODUCT,
  IMAGE,
  LIST_METAL,
  LIST_PRODUCT,
  LIST_REVIEW,
  LIST_USER_SIDE_PRODUCT,
  METAL,
  PRODUCT,
  PRODUCT_REVIEW,
  REMOVE_MEDIA,
  REMOVE_METAL,
  REMOVE_PRODUCT,
  REMOVE_REVIEW,
  REVIEW,
  REVIEW_PHOTO,
  THUMBNAIL,
  UNVERIFY_REVIEW,
  VIDEO,
} from "../../api/constApi";
import authHeader, { imageHeader } from "./authHeader";
import { apiInstance } from "./axiosApi";

export const addExcel = (payload) => {
  return apiInstance.post(EXCEL, payload, { headers: imageHeader() });
};

export const addImage = (payload) => {
  return apiInstance.post(IMAGE, payload, { headers: imageHeader() });
};

export const addThumbnail = (payload) => {
  return apiInstance.post(THUMBNAIL, payload, { headers: imageHeader() });
};

export const addVideo = (payload) => {
  return apiInstance.post(VIDEO, payload, { headers: imageHeader() });
};

export const addProduct = (payload) => {
  return apiInstance.post(PRODUCT, payload, { headers: authHeader() });
};

export const removeProduct = (payload) => {
  return apiInstance.post(REMOVE_PRODUCT, payload, { headers: authHeader() });
};

export const addMetal = (payload) => {
  return apiInstance.post(METAL, payload, { headers: authHeader() });
};

export const removeMetal = (payload) => {
  return apiInstance.post(REMOVE_METAL, payload, { headers: authHeader() });
};

export const addReview = (payload) => {
  return apiInstance.post(REVIEW, payload, { headers: authHeader() });
};

export const unverifyReview = (payload) => {
  return apiInstance.post(UNVERIFY_REVIEW, payload, {headers: authHeader()});
};

export const listReview = (payload) => {
  return apiInstance.get(`${LIST_REVIEW}?page=${payload.page}&limit=${payload.limit}`, { headers: authHeader() });
};

export const removeReview = (payload) => {
  return apiInstance.post(REMOVE_REVIEW, payload, { headers: authHeader() });
};

export const reviewPhoto = (payload) => {
  return apiInstance.post(REVIEW_PHOTO, payload);
};

export const listProduct = (payload) => {
  const category = payload.category ? payload.category : "";
  const subcategory = payload.subcategory ? payload.subcategory : "";
  const p = payload.page ? payload.page : 1;
  const l = payload.limit ? payload.limit : 50;
  return apiInstance.get(
    `${LIST_PRODUCT}?category=${category}&shop_by_style=${subcategory}&shop_by_shape=${subcategory}&top_gift_ideas=${subcategory}&gifts_by_occasion=${subcategory}&page=${p}&limit=${l}`
  );
};

export const listUserSideProduct = (payload) => {
  const category = payload.category ? payload.category : "";
  const subcategory = payload.subcategory ? payload.subcategory : "";
  const p = payload.page ? payload.page : 1;
  const l = payload.limit ? payload.limit : 50;
  const gmetal = payload.gm ? payload.gm : "Sterling Silver";
  return apiInstance.get(
    `${LIST_USER_SIDE_PRODUCT}?category=${category}&shop_by_style=${subcategory}&shop_by_shape=${subcategory}&top_gift_ideas=${subcategory}&gifts_by_occasion=${subcategory}&gmetal=${gmetal}&page=${p}&limit=${l}`
  );
};

export const listMetal = (payload) => {
  const metal = payload.metal;
  const p = payload.page;
  const l = payload.limit;
  return apiInstance.get(`${LIST_METAL}?metal=${metal}&page=${p}&limit=${l}`, {
    headers: authHeader(),
  });
};

export const getOneMetal = (payload) => {
  return apiInstance.get(
    `${GET_ONE_METAL}?productid=${payload.productId}&metal=${payload.metalType}`
  );
};

export const getOneProduct = (payload) => {
  return apiInstance.get(`${GET_ONE_PRODUCT}?productid=${payload.productid}`);
};

export const removeMedia = (payload) => {
  return apiInstance.post(REMOVE_MEDIA, payload, { headers: authHeader() });
};

export const changeProductStatus = (payload) => {
  return apiInstance.post(CHANGE_PRODUCT_STATUS, payload, { headers: authHeader() });
};
