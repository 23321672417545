import { ADD_COUPON, GET_COUPON, LIST_COUPON, REMOVE_COUPON, VALIDATE_COUPON } from "../../api/constApi";
import authHeader from "./authHeader";
import { apiInstance } from "./axiosApi";

export const addCoupon = (payload) => {
  return apiInstance.post(ADD_COUPON, payload, { headers: authHeader() });
};

export const removeCoupon = (payload) => {
  return apiInstance.post(REMOVE_COUPON, payload, { headers: authHeader() });
};

export const listCoupon = () => {
  return apiInstance.get(LIST_COUPON, { headers: authHeader() });
};

export const getCoupon = (id) => {
  return apiInstance.get(`${GET_COUPON}?couponid=${id}`, { headers: authHeader() });
};

export const validateCoupon = (payload) => {
  return apiInstance.post(VALIDATE_COUPON, payload, { headers: authHeader() });
};
