import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addToWishlist,
  checkWishlist,
  listAllWishlist,
  listWishlist,
} from "../../redux/services/wishlistServices";
import { useSelector } from "react-redux";

const initialState = {
  wishList: {},
  status: false
};

export const selectStatus = (state) => state.wishlist.status;

export const productAddToWishlist = createAsyncThunk(
  "wishlist/productAddToWishlist",
  async (payload) => {
    return await addToWishlist(payload);
  }
);

export const listWishlistProduct = createAsyncThunk(
  "wishlist/listWishlistProduct",
  async () => {
    return await listWishlist();
  }
);

export const listWishlistAll = createAsyncThunk(
  "wishlist/listWishlistAll",
  async (payload) => {
    return await listAllWishlist(payload);
  }
);

export const wishlistStatus = createAsyncThunk(
  "wishlist/wishlistStatus",
  async (payload) => {
    return await checkWishlist(payload);
  }
);

export const selectTotalWishlistProducts = (state) => state.wishlist.wishList;

const wishListSlice = createSlice({
  name: "wishlistSlice",
  initialState,
  reducers: {
    listLocalWishlistProduct: (state, action) => {
      const list = JSON.parse(localStorage.getItem("wishlist"));
      state.wishList = list;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(listWishlistProduct.fulfilled, (state, action) => {
      state.wishList = action?.payload?.data?.Data;
    });
    builder.addCase(wishlistStatus.fulfilled, (state, action) => {
      state.status = action?.payload?.data?.Data.status;
    });
  },
});
export default wishListSlice.reducer;

export const { listLocalWishlistProduct } = wishListSlice.actions;

export const useTotalProductsInWishlist = () => {
  const list = useSelector(selectTotalWishlistProducts);
  let total = 0;
  if (list && list.length > 0) {
    total = list.length;
  }
  return total;
}

export const useWishlistProducts = () => {
  const list = useSelector(selectTotalWishlistProducts);
  
  return list;
}
export const useStatus = () => {
  const status = useSelector(selectStatus);

  return status;
};

