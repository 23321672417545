import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addCoupon, getCoupon, listCoupon, removeCoupon, validateCoupon } from "../../redux/services/couponServices";

const initialState = {
  couponList: {},
  };

export const couponAdd = createAsyncThunk(
  "coupon/couponAdd",
  async (payload) => {
    return await addCoupon(payload);
  }
);

export const couponRemove = createAsyncThunk(
  "coupon/couponRemove",
  async (payload) => {
    return await removeCoupon(payload);
  }
);

export const listCoupons = createAsyncThunk(
  "coupon/listCoupon",
  async () => {
    return await listCoupon();
  }
);

export const getCouponData = createAsyncThunk("coupon/getCouponData", async (id) => {
  return await getCoupon(id);
});

export const couponValidation = createAsyncThunk("coupon/couponValidation", async (payload) => {
  return await validateCoupon(payload);
});

const couponSlice = createSlice({
  name: "couponSlice",
  initialState,
  reducers: {},
  extraReducers: {},
});

export default couponSlice.reducer;