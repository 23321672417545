import { ADDRESS, GET_ALL_USER, PROFILE, REMOVE_ADDRESS, REMOVE_USER, USER } from "../../api/constApi";
import authHeader, { imageHeader } from "./authHeader";
import { apiInstance } from "./axiosApi";

export const addUser = (payload) => {
  return apiInstance.post(USER, payload, { headers: authHeader() });
};

export const getUser = () => {
  return apiInstance.get(USER, { headers: authHeader() });
};

export const removeUser = (payload) => {
  return apiInstance.post(REMOVE_USER, payload, { headers: authHeader() });
};

export const addAddress = (payload) => {
  return apiInstance.post(ADDRESS, payload, { headers: authHeader() });
};

export const removeAddress = (payload) => {
  return apiInstance.post(REMOVE_ADDRESS, payload, { headers: authHeader() });
};

export const getAddress = () => {
  return apiInstance.get(ADDRESS, { headers: authHeader() });
};

export const getAllUser = (payload) => {
  return apiInstance.get(`${GET_ALL_USER}?page=${payload.page}&limit=${payload.limit}`, { headers: authHeader() });
};


export const profile = (payload) => {
  return apiInstance.post(PROFILE, payload, { headers: imageHeader() });
};