import { DESIGN_PIC, ENQUIRY, ENQUIRY_TAKEN, REMOVE_ENQUIRY } from "../../api/constApi";
import authHeader, { imageHeader } from "./authHeader";
import { apiInstance } from "./axiosApi";

export const enquiry = (payload) => {
  return apiInstance.post(ENQUIRY, payload);
};

export const enquiryTaken = (payload) => {
  return apiInstance.post(ENQUIRY_TAKEN, payload, {headers: authHeader()});
};

export const listEnquiry = (payload) => {
  return apiInstance.get(`${ENQUIRY}?page=${payload.page}&limit=${payload.limit}`, {headers: authHeader()});
};

export const designPic = (payload) => {
  return apiInstance.post(DESIGN_PIC, payload, {headers: imageHeader()});
};

export const deleteEnquiry = (payload) => {
  return apiInstance.post(REMOVE_ENQUIRY, payload, {headers: authHeader()});
};
