import { CHECK_USER, LIST_ORDER, LIST_USER_ORDER, PAY } from "../../api/constApi";

import authHeader from "./authHeader";
import { apiInstance } from "./axiosApi";

export const pay = (payload) => {
  return apiInstance.post(PAY, payload, { headers: authHeader() });
};

export const listOrder = (payload) => {
  return apiInstance.get(`${LIST_ORDER}?page=${payload.page}&limit=${payload.limit}`, { headers: authHeader() });
};

export const userCheck = (payload) => {
  return apiInstance.post(CHECK_USER, payload);
};

export const listUserOrder = () => {
  return apiInstance.get(LIST_USER_ORDER, { headers: authHeader() });
};
