import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addAddress,
  getAddress,
  getUser,
  profile,
  removeAddress,
} from "../../redux/services/accountServices";
import { useSelector } from "react-redux";

export const selectUserPic = (state) => state.account.profile;

const initialState = {
  profile: {},
  user: {},
  profilePic: selectUserPic ? selectUserPic : "",
};

export const getProfile = createAsyncThunk("account/getProfile", async () => {
  return await getUser();
});

export const profilePic = createAsyncThunk(
  "auth/profilePic",
  async (payload) => {
    return await profile(payload);
  }
);

export const addressAdd = createAsyncThunk(
  "account/addressAdd",
  async (payload) => {
    return await addAddress(payload);
  }
);

export const addressRemove = createAsyncThunk(
  "account/addressRemove",
  async (payload) => {
    return await removeAddress(payload);
  }
);

export const listAdress = createAsyncThunk("account/listAddress", async () => {
  return await getAddress();
});

const accountSlice = createSlice({
  name: "accountSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.profile = action?.payload?.data?.Data;
    });
    builder.addCase(profilePic.fulfilled, (state, action) => {
      state.profile = action?.payload?.data?.Data;
    });
  },
});

export const useUserProfile = () => {
  const data = useSelector(selectUserPic);
  return data;
};

export default accountSlice.reducer;
