import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addExcel, addImage, addMetal, addProduct, addReview, addThumbnail, addVideo, changeProductStatus, getOneMetal, getOneProduct, listMetal, listProduct, listReview, listUserSideProduct, removeMedia, removeMetal, removeProduct, removeReview, reviewPhoto, unverifyReview } from "../../redux/services/productServices";

const initialState = {
    productList: {}
}

export const productAdd = createAsyncThunk("product/addproduct", async (payload) => {
    return await addProduct(payload);
});

export const reviewAdd = createAsyncThunk("product/reviewAdd", async (payload) => {
    return await addReview(payload);
});

export const productRemove = createAsyncThunk("product/productRemove", async (payload) => {
    return await removeProduct(payload);
});

export const reviewRemove = createAsyncThunk("product/reviewRemove", async (payload) => {
    return await removeReview(payload);
});

export const reviewUnverify = createAsyncThunk(
    "product/reviewUnverify",
    async (payload) => {
      return await unverifyReview(payload);
    }
  );

export const reviewPhotoUpload = createAsyncThunk("product/reviewPhotoUpload", async (payload) => {
    return await reviewPhoto(payload);
});

export const reviewList = createAsyncThunk("product/reviewList", async (payload) => {
    return await listReview(payload);
});

export const metalRemove = createAsyncThunk("product/metalRemove", async (payload) => {
    return await removeMetal(payload);
});

export const uploadExcel = createAsyncThunk("product/uploadExcel", async (payload) => {
    return await addExcel(payload);
});

export const uploadPhoto = createAsyncThunk("product/uploadPhoto", async (payload) => {
    return await addImage(payload);
});

export const uploadThumbnail = createAsyncThunk("product/uploadThumbnail", async (payload) => {
    return await addThumbnail(payload);
});

export const uploadVideo = createAsyncThunk("product/uploadVideo", async (payload) => {
    return await addVideo(payload);
});

export const getProductList = createAsyncThunk("product/getProductList", async (payload) => {
    return await listProduct(payload);
});

export const getUserProductList = createAsyncThunk("product/getProductList", async (payload) => {
    return await listUserSideProduct(payload);
});

export const getMetalList = createAsyncThunk("product/getMetalList", async (payload) => {
    return await listMetal(payload);
});

export const getMetalByType = createAsyncThunk("product/getMetalByType", async (payload) => {
    return await getOneMetal(payload);
});

export const metalAdd = createAsyncThunk("product/addmetal", async (payload) => {
    return await addMetal(payload);
});

export const mediaRemove = createAsyncThunk("product/mediaRemove", async (payload) => {
    return await removeMedia(payload);
});

export const getProducOne = createAsyncThunk("product/getProductOne", async (payload) => {
    return await getOneProduct(payload);
});

export const productStatus = createAsyncThunk("product/productStatus", async (payload) => {
    return await changeProductStatus(payload);
});

const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUserProductList.fulfilled, (state, action) => {
            state.productList = action?.payload?.data?.Data;
        })
    }
});

export default productSlice.reducer;
