import {
  CATEGORY,
  CATEGORY_PIC,
  GET_ONE_CATEGORY,
  GET_ONE_METAL_CATEGORY,
  LIST_CATEGORY,
  LIST_METAL_CATEGORY,
  METAL_CATEGORY,
  METAL_DIAMOND_TYPE,
  METAL_MATERIAL,
  REMOVE_CATEGORY,
  REMOVE_METAL_CATEGORY,
  REMOVE_METAL_DIAMOND_TYPE,
  REMOVE_METAL_MATERIAL,
} from "../../api/constApi";

import authHeader, { imageHeader } from "./authHeader";
import { apiInstance } from "./axiosApi";

export const addCategory = (payload) => {
  return apiInstance.post(CATEGORY, payload, { headers: authHeader() });
};

export const removeCategory = (payload) => {
  return apiInstance.post(REMOVE_CATEGORY, payload, { headers: authHeader() });
};

export const addMetalCategory = (payload) => {
  return apiInstance.post(METAL_CATEGORY, payload, { headers: authHeader() });
};

export const categoryPic = (payload) => {
  return apiInstance.post(CATEGORY_PIC, payload, { headers: imageHeader() });
};

export const addMetalMaterial = (payload) => {
  return apiInstance.post(METAL_MATERIAL, payload, { headers: authHeader() });
};

export const addMetalDiamondType = (payload) => {
  return apiInstance.post(METAL_DIAMOND_TYPE, payload, {
    headers: authHeader(),
  });
};

export const addSubCategory = (payload) => {
  return apiInstance.post(
    `/api/${payload.shop_by}category`,
    { categoryid: payload.categoryid, [payload.shop_by+"id"]: payload.id,name: payload.name, photo: payload.photo },
    { headers: authHeader() }
  );
};

export const removeSubCategory = (payload) => {
  return apiInstance.post(
    `/api/remove${payload.shop_by}category`,
    { categoryid: payload.categoryid, [payload.shop_by+"id"]: payload.id },
    { headers: authHeader() }
  );
};

export const listCategory = () => {
  return apiInstance.get(LIST_CATEGORY, { headers: authHeader() });
};

export const getOneCategory = (category) => {
  return apiInstance.get(`${GET_ONE_CATEGORY}${category}`);
};

export const listMetalCategory = () => {
  return apiInstance.get(LIST_METAL_CATEGORY, { headers: authHeader() });
};

export const getOneMetalCategory = (metalCategory) => {
  return apiInstance.get(`${GET_ONE_METAL_CATEGORY}${metalCategory}`, {
    headers: authHeader(),
  });
};

export const removeMetalCategory = (payload) => {
  return apiInstance.post(REMOVE_METAL_CATEGORY, payload, {
    headers: authHeader(),
  });
};

export const removeMetalMaterial = (payload) => {
  return apiInstance.post(REMOVE_METAL_MATERIAL, payload, {
    headers: authHeader(),
  });
};

export const removeMetalDiamondType = (payload) => {
  return apiInstance.post(REMOVE_METAL_DIAMOND_TYPE, payload, {
    headers: authHeader(),
  });
};
