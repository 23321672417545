import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addBlog, banner, getOneBlog, listBlog, removeBLog } from "../../redux/services/blogServices";

const initialState = {
    blogList: {}
}

export const blogAdd = createAsyncThunk("blog/blogAdd", async (payload) => {
    return await addBlog(payload);
});

export const blogBanner = createAsyncThunk("blog/blogBanner", async (payload) => {
    return await banner(payload);
});

export const blogRemove = createAsyncThunk("blog/blogRemove", async (payload) => {
    return await removeBLog(payload);
});

export const getBlogList = createAsyncThunk("blog/getBlogList", async (payload) => {
    return await listBlog(payload);
});

export const getBlog = createAsyncThunk("blog/getBlog", async (payload) => {
    return await getOneBlog(payload);
});

const blogSlice = createSlice({
    name: "blog",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getBlogList.fulfilled, (state, action) => {
            state.blogList = action?.payload?.data?.Data;
        })
    }
});

export default blogSlice.reducer;
