import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  getAllUser,
  getUser,
  removeUser,
} from "../../redux/services/accountServices";
import {
  changePassword,
  forgotPassword,
  signIn,
  signUp,
  unverifyUser,
  verifyOtp,
} from "../../redux/services/authServices";

const getDefaultUser = () => {
  let user = localStorage.getItem("user");
  if (user && user !== "undefined") {
    return JSON.parse(user);
  } else {
    return null;
  }
};
const initialState = {
  user: localStorage.getItem("user") ? getDefaultUser() : {},
  profile: {},
  userid: "",
};

export const getProfile = createAsyncThunk("auth/getProfile", async () => {
  return await getUser();
});

export const deleteUserById = createAsyncThunk(
  "auth/deleteUserById",
  async (payload) => {
    return await removeUser(payload);
  }
);

export const getUserList = createAsyncThunk(
  "auth/getUserList",
  async (payload) => {
    return await getAllUser(payload);
  }
);

export const isAdmin = () => {
  getProfile();
};

export const login = createAsyncThunk("auth/login", async (payload) => {
  return await signIn(payload);
});

export const passwordForgot = createAsyncThunk(
  "auth/passwordForgot",
  async (payload) => {
    return await forgotPassword(payload);
  }
);

export const otpVerify = createAsyncThunk("auth/otpVerify", async (payload) => {
  return await verifyOtp(payload);
});

export const passwordChange = createAsyncThunk(
  "auth/passwordChange",
  async (payload) => {
    return await changePassword(payload);
  }
);

export const register = createAsyncThunk("auth/register", async (payload) => {
  return await signUp(payload);
});

export const userUnverify = createAsyncThunk(
  "auth/userUnverify",
  async (payload) => {
    return await unverifyUser(payload);
  }
);

export const selectUserPic = (state) => state.auth.profile;
export const selectUserId = (state) => state.auth.userid;

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    removeToken: (state, action) => {
      localStorage.clear();
      state.user = {};
      state.profile = {};
    },
    setKey: (state, action) => {
      state.key = action.payload;
    },
    setGoogleToken: (state, action) => {
      localStorage.setItem(
        "user",
        action.payload ? JSON.stringify(action.payload) : undefined
      );
      state.user = action.payload ? action.payload : {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      let user = action?.payload?.data?.Data;
      localStorage.setItem("user", user ? JSON.stringify(user) : undefined);
      state.user = user ? user : {};
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.profile = action?.payload?.data?.Data;
      state.userid = action?.payload?.data?.Data?._id;
    });
  },
});
export default authSlice.reducer;

export const { removeToken, setKey, setGoogleToken } = authSlice.actions;

export const selectUser = (state) => state.auth.user;

export const selectUserRole = (state) => state.auth.profile.role;

export const selectUserAuthenticate = (state) => state.auth.user;

export const useUser = () => {
  const userToken = useSelector(selectUser);
  return userToken;
};

export const selectKey = (state) => state.auth.key;

export const useKey = () => {
  const key = useSelector(selectKey);
  return useMemo(() => ({ key }), [key]);
};

export const useRole = () => {
  const role = useSelector(selectUserRole);

  return role;
};

export const useUserProfile = () => {
  const data = useSelector(selectUserPic);

  return data;
};

export const useUserId = () => {
  const data = useSelector(selectUserId);

  return data;
};

export const useAuthenticate = () => {
  const authenticate = useSelector(selectUserAuthenticate);

  return authenticate;
};
