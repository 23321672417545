import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteEnquiry,
  designPic,
  enquiry,
  enquiryTaken,
  listEnquiry,
} from "../../redux/services/enquiryServices";

const initialState = {
  enquiryList: {},
};

export const addEnquiry = createAsyncThunk(
  "enquiry/addEnquiry",
  async (payload) => {
    return await enquiry(payload);
  }
);

export const getEnquiry = createAsyncThunk(
  "enquiry/getEnquiry",
  async (payload) => {
    return await listEnquiry(payload);
  }
);

export const enquiryDone = createAsyncThunk(
  "enquiry/enquiryDone",
  async (payload) => {
    return await enquiryTaken(payload);
  }
);

export const enquiryDelete = createAsyncThunk(
  "enquiry/enquiryDelete",
  async (payload) => {
    return await deleteEnquiry(payload);
  }
);

export const uploadDesign = createAsyncThunk(
  "enquiry/uploadDesign",
  async (payload) => {
    return await designPic(payload);
  }
);

const enquirySlice = createSlice({
  name: "enquirySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEnquiry.fulfilled, (state, action) => {
      state.enquiryList = action?.payload?.data?.Data;
    });
  },
});
export default enquirySlice.reducer;
