import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authSlice from "../component/auth/authSlice";
import accountSlice from "../pages/account/accountSlice";
import categorySlice from "../pages/Admin/categorySlice";
import productSlice from "../pages/Admin/productSlice";
import cartSlice from "../pages/Admin/cartSlice";
import wishlistSlice from "../pages/wishlist/wishlistSlice";
import orderSlice from "../pages/account/orderSlice";
import blogSlice from "../pages/blog/blogSlice";
import enquirySlice from "../pages/product/enquirySlice";
import couponSlice from "../pages/Admin/couponSlice";

const combineReducer = combineReducers({
  auth: authSlice,
  account: accountSlice,
  category: categorySlice,
  product: productSlice,
  cart: cartSlice,
  wishlist: wishlistSlice,
  order: orderSlice,
  blog: blogSlice,
  enquiry: enquirySlice,
  coupon: couponSlice
});

const store = configureStore({
  reducer: combineReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(),
});

export default store;
